export const CONSTANTS_OBIE = Object.freeze({
  API: {
    GET_APIS_LIST: 'digital-content/page',
    GET_APIS_LIST_AI: 'search/ai',
    GET_APIS_LIST_API_AFFINITY: 'ai/affinity ',
    GET_CONTENT_RATING: 'ratings',
    GET_DETAILS: 'digital-content/detail',
    GET_AFFINITY_REFRESH: 'ai/affinity/refresh',
    API_AFFINITY_CACHE_CLEAR: 'ai/affinity/invalidate',
    AI_AFFINITY_SETTINGS_MINIMUM_THRESHOLD_VAL: 'org/settings/affinity',
    GET_FAVORITE_APIS: 'users/favourites/retrieve',
    ADD_REMOVE_FAVORITE: 'users/favourites',
    RETRIVE_FIRST_LEVEL_BIAN_DATA: '/taxa/level/1',
    RETRIVE_MID_LEVEL_BIAN_DATA: '/taxa/retrieve-by-parent-id/',
    RETRIVE_LAST_LEVEL_BIAN_DATA: '/associated-digital-contents',
    RETRIVE_TEXONOMY_DEFINATIONS: 'taxonomies/definitions/get',
  },
});
